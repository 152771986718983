<template>
  <div>
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4"
    >
      <vx-card class="mb-4 col-span-3">
        <div class="flex flex-wrap lg:flex-no-wrap gap-3 items-end">
          <div>
            <vs-button
              color="success"
              icon="check"
              @click="changeStatusWithdrawRequestDialog(true)"
              >Aprobar
            </vs-button>
          </div>

          <div>
            <vs-button
              color="danger"
              icon="close"
              @click="changeStatusWithdrawRequestDialog(false)"
              >Denegar
            </vs-button>
          </div>

          <vs-select
            class=""
            label="Cuenta de depósito:"
            v-model="accountToMove"
          >
            <vs-select-item
              :key="index"
              :value="account.id"
              :text="account.name"
              v-for="(account, index) in accounts"
            />
          </vs-select>
        </div>
      </vx-card>

      <section class="flex gap-3" v-if="!totalWithdrawRequestHistoryLoading">
        <statistics-card-line
          hideChart
          class="mb-4"
          icon-right
          icon="CheckCircleIcon"
          :statistic="amountSelected | currencyFormat"
          statisticTitle="Total seleccionado"
          color="success"
        />
        <statistics-card-line
          hideChart
          class="mb-4"
          icon-right
          icon="ArrowDownIcon"
          :statistic="totalWithdrawRequestHistory.total | currencyFormat"
          :statisticTitle="'Total a retirar'"
          color="danger"
        />
      </section>

      <section
        v-if="totalWithdrawRequestHistoryLoading"
        class="w-full flex gap-3"
      >
        <div
          class="w-full bg-grey bg-opacity-25 h-[126px] mb-4 rounded-lg"
        ></div>
        <div
          class="w-full bg-grey bg-opacity-25 h-[126px] mb-4 rounded-lg"
        ></div>
      </section>
    </div>

    <vs-prompt
      :active.sync="toggleEditWithdrawImagePrompt"
      class="modal"
      title="Cargar foto"
      @accept="importImage"
      @cancel="resetImagePrompt"
      accept-text="Cargar"
      cancel-text="Descartar"
    >
      <div>
        <h1 class="font-semibold text-lg mb-4">Sube un la imagen que desea</h1>
        <UploadImageModal
          :currentImage="currentImage"
          :onSuccess="onUploadImage"
        />
      </div>
    </vs-prompt>

    <vx-card>
      <div class="flex justify-between items-center gap-3">
        <div class="w-full">
          <label class="text-sm text-san-juan font-bold">Buscar</label>
          <vs-input
            class="mb-4 w-full md:mb-0 mr-4"
            v-model="searchQuery"
            placeholder="Buscar"
          />
        </div>
        <div class="flex gap-3">
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold"
              >Fecha de inicio</label
            >
            <datepicker
              :language="langEs"
              name="start-date"
              v-model="startDate"
            ></datepicker>
          </div>
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
            <datepicker
              :language="langEs"
              name="end-date"
              v-model="endDate"
            ></datepicker>
          </div>
          <vs-select
            label="Contratistas"
            class="w-full"
            v-model="deliverymanSelected"
            placeholder="Seleccione un contratista"
            autocomplete
          >
            <vs-select-item :value="'ALL'" :text="'Todos'" />
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="`${item.code ? item.code : 'N/A'} - ${
                item.user.first_name + ' ' + item.user.last_name
              }`"
              v-for="(item, index) in deliverymen"
            />
          </vs-select>
          <div class="mt-6">
            <vs-button
              type="border"
              class="mb-4 md:mb-0 mr-3 whitespace-no-wrap"
              @click="exportWithdrawRequest"
            >
              Exportar
            </vs-button>
          </div>
        </div>
      </div>

      <div v-if="!loading && !withdrawRequestHistoryLoading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="withdrawRequestHistory"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          @grid-ready="onGridReady"
          @selectionChanged="onSelectionChanged"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import { useAgGrid } from '@/composable/useAgGrid.js';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';

import CellRendererBank from './cell-renderer/CellRendererBank.vue';
import CellRendererImage from './cell-renderer/CellRendererImage.vue';
import CellRendererEditImage from './cell-renderer/CellRendererEditImage.vue';
import CellRendererWithdrawRequestHistoryStatus from './cell-renderer/CellRendererWithdrawRequestHistoryStatus.vue';
import UploadImageModal from '@/components/socio/modals/UploadImageModal.vue';
import CellRendererCopyField from './cell-renderer/CellRendererCopyField.vue';

import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';

import {
  UPDATE_WITHDRAW_REQUEST_STATUS,
  UPDATE_WITHDRAW_REQUEST_IMAGE,
} from '@/graphql/mutations';
import {
  ACCOUNTS,
  DELIVERYMEN,
  WITHDRAW_REQUESTS_HISTORY,
  WITHDRAW_REQUESTS_HISTORY_TOTAL,
} from '@/graphql/queries.js';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererPercentAmount,
    CellRendererBank,
    CellRendererWithdrawRequestHistoryStatus,
    CellRendererImage,
    CellRendererEditImage,
    UploadImageModal,
    CellRendererCopyField,
    Datepicker,
    StatisticsCardLine,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 100,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          headerName: 'Estado',
          field: 'status',
          width: 120,
          filter: true,
          cellRendererFramework: 'CellRendererWithdrawRequestHistoryStatus',
        },
        {
          headerName: 'Código Aliado',
          field: 'deliveryman.code',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Nombre Aliado',
          field: 'deliveryman.user.first_name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Apellido Aliado',
          field: 'deliveryman.user.last_name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Cantidad',
          field: 'amount',
          filter: true,
          width: 130,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Cuenta bancaria',
          field: 'bankAccount.name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Banco',
          field: 'bankAccount.bank',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererBank',
        },
        {
          headerName: 'Número de cuenta',
          field: 'bankAccount.number',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererCopyField',
        },
        {
          headerName: 'Número de cédula',
          field: 'bankAccount.document',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererCopyField',
        },
        {
          headerName: 'Evidencia',
          field: 'image',
          width: 120,
          filter: true,
          cellRendererFramework: 'CellRendererImage',
        },
        {
          headerName: 'Editar',
          field: 'image',
          width: 120,
          filter: true,
          cellRendererFramework: 'CellRendererEditImage',
        },
        {
          headerName: 'Creación',
          field: 'created_at',
          width: 200,
          filter: true,
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',
      startDate: new Date(),
      endDate: new Date(),
      deliverymanSelected: null,
      accountToMove: null,
      toggleEditWithdrawImagePrompt: false,
      currentWithdrawRequest: null,
      currentImage: null,
      amountSelected: 0,
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');
    const paginationPageSize = ref('');

    const deliverymanSelected = computed(() =>
      state.deliverymanSelected !== 'ALL' ? state.deliverymanSelected : null
    );
    const sDate = computed(() => moment(state.startDate).format('YYYY-MM-DD'));
    const eDate = computed(() => moment(state.endDate).format('YYYY-MM-DD'));

    const {
      result: withdrawRequestHistoryResult,
      loading: withdrawRequestHistoryLoading,
      refetch: withdrawRequestHistoryRefetch,
    } = useQuery(WITHDRAW_REQUESTS_HISTORY, {
      page: currentPage,
      first: first,
      search: searchQuery,
      startDate: sDate,
      endDate: eDate,
      deliveryman_id: deliverymanSelected,
      status: 'PENDING',
    });

    const {
      result: totalWithdrawRequestHistoryResult,
      loading: totalWithdrawRequestHistoryLoading,
      refetch: totalWithdrawRequestHistoryRefetch,
    } = useQuery(WITHDRAW_REQUESTS_HISTORY_TOTAL, {
      status: 'PENDING',
    });

    watch(withdrawRequestHistoryLoading, (value) => {
      state.loading = value;
    });

    const withdrawRequestHistory = useResult(
      withdrawRequestHistoryResult,
      [],
      (data) => data.withdrawRequestHistory.data
    );

    const totalWithdrawRequestHistory = useResult(
      totalWithdrawRequestHistoryResult,
      { total: 0 },
      (data) => {
        return data.withdrawRequestHistoryTotal;
      }
    );

    const paginatorInfo = useResult(
      withdrawRequestHistoryResult,
      [],
      (data) => ({
        total: data.withdrawRequestHistory.paginatorInfo.total,
        perPage: data.withdrawRequestHistory.paginatorInfo.perPage,
      })
    );

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const { result: deliverymenResult, loading: deliverymanLoading } =
      useQuery(DELIVERYMEN);
    const deliverymen = useResult(
      deliverymenResult,
      [],
      (data) => data.deliverymen
    );
    watch(deliverymanLoading, (value) => {
      state.loading = value;
    });

    const { result: accountsResult, loading: accountsLoading } =
      useQuery(ACCOUNTS);
    const accounts = useResult(accountsResult, [], (data) => data.accounts);
    watch(accountsLoading, (value) => {
      state.loading = value;
    });

    const { state: agGridState, onGridReady } = useAgGrid({ root });

    const changeStatusWithdrawRequest = async (status) => {
      const newStatus = status ? 'APPROVED' : 'DENIED';
      console.log(state.accountToMove);
      if (newStatus == 'APPROVED' && !state.accountToMove) {
        root.$vs.notify({
          icon: 'icon-alert-circle',
          color: 'warning',
          iconPack: 'feather',
          title: 'No se puede cambiar el estado',
          text: 'Debe seleccionar una caja para realizar el movimiento.',
        });
        return;
      }
      const withdrawRequestSelected = agGridState.gridOptions.api
        .getSelectedRows()
        .map((r) => r.id);

      if (!withdrawRequestSelected || withdrawRequestSelected.length == 0) {
        root.$vs.notify({
          icon: 'icon-alert-circle',
          color: 'warning',
          iconPack: 'feather',
          title: 'No se puede cambiar el estado',
          text: 'Debe seleccionar al menos una solicitud',
        });
        return;
      }

      try {
        state.loading = true;
        await root.$apollo.mutate({
          mutation: UPDATE_WITHDRAW_REQUEST_STATUS,
          variables: {
            input: {
              withdrawRequests: withdrawRequestSelected,
              status: newStatus,
              account: state.accountToMove,
            },
          },
        });

        root.$vs.notify({
          color: 'success',
          title: 'Correctamente Actualizado',
          text: 'Se actualizó la imagen de evidencia de la solicitud.',
        });
        withdrawRequestHistoryRefetch();
        totalWithdrawRequestHistoryRefetch();

        state.loading = false;
      } catch (error) {
        state.loading = false;
        console.log(error);
      }
    };

    const changeStatusWithdrawRequestDialog = (status) => {
      root.$vs.dialog({
        type: 'confirm',
        color: `${status ? 'success' : 'danger'}`,
        title: `Confirmar cambio de estado`,
        text: `¿Estás seguro de ${
          status ? 'aprobar' : 'denegar'
        } las solicitudes seleccionadas?`,
        accept: () => {
          changeStatusWithdrawRequest(status);
        },
        acceptText: `${status ? 'Aprobar' : 'Denegar'}`,
        cancelText: 'Cancelar',
      });
    };

    const editImage = ({ withdrawRequest }) => {
      state.currentImage = null;
      state.toggleEditWithdrawImagePrompt = true;
      state.currentWithdrawRequest = withdrawRequest;
    };

    const onUploadImage = (image) => {
      state.currentImage = image;
    };
    const importImage = async () => {
      if (!state.currentImage) {
        root.$vs.notify({
          color: 'warning',
          title: 'Imagen obligatoria',
          text: 'Debe haber cargado una imagen.',
        });
        return;
      }
      try {
        state.loading = true;
        await root.$apollo.mutate({
          mutation: UPDATE_WITHDRAW_REQUEST_IMAGE,
          variables: {
            input: {
              id: state.currentWithdrawRequest.id,
              image: state.currentImage,
            },
          },
        });

        root.$vs.notify({
          color: 'success',
          title: 'Correctamente Actualizado',
          text: 'Se actualizó la aprobación de las solicitudes.',
        });
        withdrawRequestHistoryRefetch();
        totalWithdrawRequestHistoryRefetch();

        state.loading = false;
      } catch (error) {
        state.loading = false;
        console.log(error);
      }
    };
    const resetImagePrompt = () => {
      state.currentImage = null;
    };

    const copyValue = ({ value }) => {
      navigator.clipboard.writeText(value);
      root.$vs.notify({
        color: 'success',
        title: '¡Copiado!',
        text: 'Se ha copiado en el portapapeles correctamente.',
      });
    };

    const exportWithdrawRequest = () => {
      const fileName = 'solicitudes.xlsx';
      root.$http
        .post(
          'api/withdraw-requests/export',
          {
            search: searchQuery.value,
            startDate: sDate.value,
            endDate: eDate.value,
            deliveryman_id: deliverymanSelected.value,
            status: 'PENDING',
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    const onSelectionChanged = () => {
      const selectedRows = agGridState.gridOptions.api.getSelectedRows();
      const totalSelected = selectedRows.reduce(
        (acc, row) => acc + row.amount,
        0
      );

      state.amountSelected = totalSelected;
    };

    return {
      ...toRefs(state),
      withdrawRequestHistoryLoading,
      paginationPageSize,
      agGridState,
      totalPages,
      currentPage,
      withdrawRequestHistory,
      searchQuery,
      deliverymen,
      langEs: es,
      onGridReady,
      accounts,
      editImage,
      importImage,
      onUploadImage,
      resetImagePrompt,
      changeStatusWithdrawRequestDialog,
      changeStatusWithdrawRequest,
      copyValue,
      exportWithdrawRequest,

      totalWithdrawRequestHistoryRefetch,
      totalWithdrawRequestHistory,
      totalWithdrawRequestHistoryLoading,
      onSelectionChanged,
    };
  },
  created() {
    this.agGridState.gridOptions.context = {
      componentParent: this,
    };
  },
});
</script>
